<template>
    <div>
        <div class="data_group">
            <strong>ดาวน์โหลดข้อมูลคุณภาพอากาศย้อนหลัง ทุกสถานี</strong>
            <div style="margin-top:15px;">
                ฝุ่นละออง PM2.5 เฉลี่ย 24 ชั่วโมง พ.ศ. 2554 - 2567
            </div>
            <div style="margin-top:15px;padding:0;">
                <b-button variant="outline-primary" @click="loadFile">กดเพื่อดาวโหลดข้อมูลย้อนหลัง</b-button>
            </div>
            <div style="margin-top:15px;">
                ฝุ่นละออง PM10 เฉลี่ย 24 ชั่วโมง พ.ศ. 2554 - 2567
            </div>
            <div style="margin-top:15px;padding:0;">
                <b-button variant="outline-primary" @click="loadFile2">กดเพื่อดาวโหลดข้อมูลย้อนหลัง</b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'history_day',
    methods : {
        loadFile(){
            window.location = 'http://air4thai.pcd.go.th/webV2/history/pm25_2011_2020.zip'
        },
        loadFile2(){
            window.location = 'http://air4thai.pcd.go.th/webV2/history/pm10_2011_2020.zip'
        }
    }
}
</script>

<style lang="scss" scoped>
.data_group{
    text-align: left;
    background: rgba(1, 111, 183, 0.05);
    border-radius: 5px;
    padding:20px;
}
</style>